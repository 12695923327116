<template lang="pug">
  .setting-list
    .setting-list-header
      .car-class-name {{ $t("inventory_management.inventory_long_period.setting_list.car_class_name") }}
      .shared-inventory-limit {{ $t("inventory_management.inventory_long_period.setting_list.shared_inventory") }}
      .sale-setting
        .ota-name
        .add-sale-stop
          AppRadioButton(
            :value="isCommonAddSaleStop"
            :indeterminate="isCommonIndeterminatedAddSaleStop"
            @change="setSaleStopForAllCarClasses($event)"
          )
          span {{ $t("inventory_management.inventory_long_period.setting_list.sale_stop") }}
          AppTooltip.sale-stop-tooltip(
            :title="$t('inventory_management.inventory_long_period.setting_list.sale_stop_tooltip')"
          )
        .delete-sale-stop
          AppRadioButton(
            :value="isCommonDeleteSaleStop"
            :indeterminate="isCommonIndeterminatedDeleteSaleStop"
            @change="setSaleStopForAllCarClasses($event, false)"
          )
          span {{ $t("inventory_management.inventory_long_period.setting_list.sale") }}
    .setting-list-body
      TransitionGroup(
        name="fade-zoom"
        appear
        tag="div"
        class="transition-container"
      )
        .setting(
          v-for="(setting, order) in settings"
          :key="order"
        )
          .car-class-name-wrapper
            CarClassName(:car-class="setting.carClass")
          .shared-inventory-limit
            AppNumberInput(
              :value="setting.shared_inventory_limit"
              @input="handleChangeSetting(order, { shared_inventory_limit: $event })"
            )
          SaleSettings(
            :sale-settings="setting.sale_settings"
            @change="handleChangeSaleSettings(order, $event)"
          )
</template>

<script>
  import setForLongPeriod from "@/mixins/inventories/for_long_period/settings-list"

  export default {
    mixins: [setForLongPeriod]
  }
</script>

<style lang="sass" scoped>
  $car-class-name-width: 260px
  $inventory-width: 200px
  $inventory-input-width: 150px

  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/mixins/set-inventory-for-long-period.sass"
  @import "@/assets/styles/mixins/inventory_for_long_period/setting-list.sass"
</style>
